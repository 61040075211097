var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.MenuList),function(data,index){return [(data.child)?[_c('li',{key:index,staticClass:"menu-item menu-item-submenu",class:{
          'menu-item-open-active menu-item-open': _vm.isRouteActive(
            data?.menu,
            data?.submenu
          ),
        },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-custom-menu-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/' + data?.icon + '.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(data?.title))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},_vm._l((data?.submenu),function(submenu,submenu_index){return _c('router-link',{key:`${index}-${submenu_index}`,attrs:{"to":{
                name: submenu.route?.name,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('v-tooltip',{attrs:{"right":"","content-class":"custom-right-tooltip menu-tooltip-hover hide-menu-tooltip-hover"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('li',_vm._g(_vm._b({staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},'li',attrs,false),on),[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(submenu.text))])])])]}}],null,true)},[_c('span',{staticClass:"menu-tooltip-hover-target"},[_vm._v(_vm._s(submenu.text))])])]}}],null,true)})}),1)])])]:[(_vm.getPermission)?[_c('router-link',{key:index,attrs:{"to":{ name: data?.route?.name }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('v-tooltip',{attrs:{"right":"","content-class":"custom-right-tooltip menu-tooltip-hover hide-menu-tooltip-hover"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('li',_vm._g(_vm._b({staticClass:"menu-item",class:[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},'li',attrs,false),on),[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-custom-menu-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/' + data?.icon + '.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.getMenuTitle(data)))])])])]}}],null,true)},[_c('span',{staticClass:"menu-tooltip-hover-target"},[_vm._v(_vm._s(_vm.getMenuTitle(data)))])])]}}],null,true)})]:_vm._e()]]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }