<template>
  <ul class="menu-nav">
    <template v-for="(data, index) in MenuList">
      <template v-if="data.child">
        <li
          aria-haspopup="true"
          :key="index"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="{
            'menu-item-open-active menu-item-open': isRouteActive(
              data?.menu,
              data?.submenu
            ),
          }"
        >
          <!-- menu-toggle -->
          <a href="#" class="menu-link menu-toggle">
            <span class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + data?.icon + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            <span class="menu-text">{{ data?.title }}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <router-link
                v-for="(submenu, submenu_index) in data?.submenu"
                :key="`${index}-${submenu_index}`"
                :to="{
                  name: submenu.route?.name,
                }"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <v-tooltip
                  right
                  content-class="custom-right-tooltip menu-tooltip-hover hide-menu-tooltip-hover"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      v-bind="attrs"
                      v-on="on"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ]"
                    >
                      <a :href="href" class="menu-link" v-on:click="navigate">
                        <i class="menu-bullet menu-bullet-dot">
                          <span></span>
                        </i>
                        <span class="menu-text">{{ submenu.text }}</span>
                      </a>
                    </li>
                  </template>
                  <span class="menu-tooltip-hover-target">{{
                    submenu.text
                  }}</span>
                </v-tooltip>
              </router-link>
            </ul>
          </div>
        </li>
      </template>
      <template v-else>
        <template v-if="getPermission">
          <router-link
            :key="index"
            :to="{ name: data?.route?.name }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <v-tooltip
              right
              content-class="custom-right-tooltip menu-tooltip-hover hide-menu-tooltip-hover"
            >
              <template v-slot:activator="{ on, attrs }">
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  v-bind="attrs"
                  v-on="on"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <span
                      class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4"
                    >
                      <!--begin::Svg Icon-->
                      <!-- <inline-svg :src="$assetURL(data.icon)" /> -->
                      <!--  <inline-svg
                :src="
                  $assetURL('media/custom-svg/' + getGroupTitle(data) + '.svg')
                "
              /> -->
                      <inline-svg
                        :src="
                          $assetURL('media/custom-svg/' + data?.icon + '.svg')
                        "
                      />
                      <!--end::Svg Icon-->
                    </span>
                    <span class="menu-text">{{ getMenuTitle(data) }}</span>
                  </a>
                </li>
              </template>
              <span class="menu-tooltip-hover-target">{{
                getMenuTitle(data)
              }}</span>
            </v-tooltip>
          </router-link>
        </template>
      </template>
    </template>
  </ul>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import { mapGetters } from "vuex";
import inflect from "i";
export default {
  name: "KTMenu",
  data() {
    return {
      MenuList: [],
      /*  group_setting: {}, */
    };
  },
  methods: {
    getMenuTitle(row) {
      if (!row) {
        return null;
      }

      if (row.menu != "dashboard" && row.menu != "calendar") {
        return inflect().pluralize(row.title);
      } else {
        return row.title;
      }
    },
    getGroupTitle(row) {
      for (let i = 0; i < row.length; i++) {
        if (row[i].group) {
          console.log(row[i].group);
          return row[i].group;
        }
      }
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    isRouteActive(match, children = null) {
      if (children && children.length) {
        // Check if any of the child routes are active
        const isChildActive = children.some(
          (child) => this.$route.name === child.route.name
        );

        if (isChildActive) {
          return true;
        }
      }

      // Check if the current route matches the parent's route
      return this.$route.meta.menuActive === match;
    },
    /* isRouteActive(match, children = null) {
      if (children) {
        let cc = children.map((mm) => mm.name);
        return cc.includes(this.$route.meta.menuActive);
      }
      if (isEmpty(this.$route.meta) === false) {
        return this.$route.meta.menuActive === match;
      }
      return false;
    }, */
    isChildActive(row) {
      let isActive = false;
      for (let i = 0; i < row.length; i++) {
        isActive = this.isRouteActive(row[i].menu);
        if (isActive) {
          return isActive;
        }
      }
      return isActive;
    },

    getMenuList() {
      const _this = this;
      try {
        const result = JwtService.menuList();
        const arrays = [];
        const objects = [];
        const topTwo = [];
        const lastOne = [];
        for (const menu in result) {
          if (_this.lodash.isArray(result[menu])) {
            arrays.push(result[menu]);
          } else if (
            _this.lodash.isObject(result[menu]) &&
            result[menu].order <= 1
          ) {
            topTwo.push(result[menu]);
          } else if (
            _this.lodash.isObject(result[menu]) &&
            result[menu].order >= 21
          ) {
            lastOne.push(result[menu]);
          } else if (_this.lodash.isObject(result[menu])) {
            objects.push(result[menu]);
          }
        }
        _this.MenuList = [...topTwo, ...arrays, ...objects, ...lastOne];

        if (_this.lodash.isEmpty(_this.MenuList)) {
          JwtService.destroyToken();
        }
      } catch (error) {
        _this.logError(error);
        JwtService.destroyToken();
      }
    },
    toRemoveSubmenu(name, toRemoveSubmenuName) {
      const _submenu = this.MenuList.find(
        (menu) => menu.name === name
      )?.submenu;
      const mod_submenu = _submenu.filter((v) => v.name != toRemoveSubmenuName);
      const req_idx = this.MenuList.findIndex((v) => v.name == name);
      return {
        submenu: _submenu,
        mod_submenu: mod_submenu,
        req_idx,
      };
    },
  },
  watch: {
    /*  group_setting: {
      deep: true,
      handler: function (settings) {
        if (settings.group_custom_fields == false) {
          const data = this.toRemoveSubmenu("inevtories", "group");
          this.menuList[data.req_idx].submenu =
            data.req_idx > -1 ? data.mod_submenu : data.submenu;
        }
      },
    }, */
  },
  computed: {
    ...mapGetters(["localDB"]),
  },
  mounted() {
    this.getMenuList();
  },
};
</script>
